import {Component, NgZone, OnInit} from '@angular/core';
import {HttpService} from '../services/HttpService';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToasterService} from 'angular2-toaster';

@Component({
    selector: 'app-reporting',
    templateUrl: './reporting.component.html',
    styleUrls: ['./reporting.component.scss'],
    providers: [HttpService]
})
export class ReportingComponent implements OnInit {

    public stepToShow = 0;

    public dbRepsonse;

    public totalsToShow = 0;
    public successToShow = 0;
    public failedToShow = 0;
    public pendingToShow = 0;
    public incompleteToShow = 0;
    public notStarted = 0;

    public kycToDisplay;

    public showKycModal = false;
    public showInfoModal = false;
    public sortByVal = '';
    public reverse = false;

    public pagination;

    public itemsToDisplay;

    public allItems = [];

    public inputData = {
        from: '',
        to: '',
        email_option: ''
    };

    public User_Details;

    public statusToUpdate = 0;

    public showEmailModal = false;

    constructor(
        public http: HttpService,
        public loading: NgxSpinnerService,
        public toast: ToasterService,
        public zone: NgZone
    ) {

    }

    ngOnInit() {
        this.User_Details = JSON.parse(localStorage.getItem('User_Details'));
        this.loading.show();
        this.http.makeRequestPost(['get_reporting_data', {}])
        .subscribe(
            (resp) => {
                this.loading.hide();
                if (resp.data) {
                    this.dbRepsonse = resp.data;
                    console.log(resp.data);
                    this.totalsToShow = resp.data.all.total;
                    this.successToShow = resp.data.all.success;
                    this.failedToShow = resp.data.all.failed;
                    this.pendingToShow = resp.data.all.pending;
                    this.incompleteToShow = resp.data.all.incomplete;
                    this.notStarted = resp.data.all.not_started;
                    this.allItems = resp.data.all.data;

                    this.setStepToShow(1);
                } else {
                    this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                }
            },
            (error) => {
                this.loading.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }

    setStepToShow(num) {
        console.log(this.dbRepsonse);
        this.sortByVal = '';
        this.reverse = true;
        this.stepToShow = num;
        switch (num) {
            case 0: {
                this.totalsToShow = this.dbRepsonse.all.total;
                this.successToShow = this.dbRepsonse.all.success;
                this.failedToShow = this.dbRepsonse.all.failed;
                this.pendingToShow = this.dbRepsonse.all.pending;
                this.incompleteToShow = this.dbRepsonse.all.incomplete;
                this.notStarted = this.dbRepsonse.all.not_started;
                this.allItems = this.dbRepsonse.all.data;

                break;
            }
            case 1: {
                this.totalsToShow = this.dbRepsonse.day.total;
                this.successToShow = this.dbRepsonse.day.success;
                this.failedToShow = this.dbRepsonse.day.failed;
                this.pendingToShow = this.dbRepsonse.day.pending;
                this.incompleteToShow = this.dbRepsonse.day.incomplete;
                this.notStarted = this.dbRepsonse.day.not_started;
                this.allItems = this.dbRepsonse.day.data;
                break;
            }
            case 2: {
                this.totalsToShow = this.dbRepsonse.week.total;
                this.successToShow = this.dbRepsonse.week.success;
                this.failedToShow = this.dbRepsonse.week.failed;
                this.pendingToShow = this.dbRepsonse.week.pending;
                this.incompleteToShow = this.dbRepsonse.week.incomplete;
                this.notStarted = this.dbRepsonse.week.not_started;
                this.allItems = this.dbRepsonse.week.data;
                break;
            }
            case 3: {
                this.totalsToShow = this.dbRepsonse.month.total;
                this.successToShow = this.dbRepsonse.month.success;
                this.failedToShow = this.dbRepsonse.month.failed;
                this.pendingToShow = this.dbRepsonse.month.pending;
                this.incompleteToShow = this.dbRepsonse.month.incomplete;
                this.notStarted = this.dbRepsonse.month.not_started;
                this.allItems = this.dbRepsonse.month.data;
                break;
            }
            case 4: {
                break;
            }


        }
        if (this.allItems.length == 0) {
            this.itemsToDisplay = [];
        }
        // console.log(this.allItems);
    }

    onChangePage(evt) {
        // console.log(evt);
        this.pagination = evt.pager;
        this.itemsToDisplay = evt.pageOfItems;
    }

    toggleKycModal(kyc) {
        console.log(kyc);
        setTimeout(() => {
            this.zone.run(() => {
                for (var i = 0; i < this.itemsToDisplay.length; i++) {
                    this.itemsToDisplay[i].showOptions = false;
                }
            });
        });

        this.kycToDisplay = kyc;
        this.showKycModal = true;
    }

    toggleInfo(kyc: any) {
        setTimeout(() => {
            this.zone.run(() => {
                for (var i = 0; i < this.itemsToDisplay.length; i++) {
                    this.itemsToDisplay[i].showOptions = false;
                }
            });
        });

        this.kycToDisplay = kyc;
        this.kycToDisplay.id_face_result = JSON.parse(this.kycToDisplay.id_face_result);
        this.kycToDisplay.selfie_face_result = JSON.parse(this.kycToDisplay.selfie_face_result);
        this.kycToDisplay.id_text_result = JSON.parse(this.kycToDisplay.id_text_result);
        this.kycToDisplay.compare_result = this.kycToDisplay.compare_result;
        this.showInfoModal = true;
    }

    sortBy(value) {
        this.sortByVal = value;
        // console.log(this.pagination);
        this.reverse = !this.reverse;

        this.allItems = this.allItems.sort((a, b) => {
            return this.sort(a, b, value);
        });


        if (this.pagination.currentPage == 1) {
            this.itemsToDisplay = this.allItems.slice(0, this.pagination.pageSize);
        } else {
            const startIdx = ((this.pagination.currentPage - 1) * this.pagination.pageSize);
            const endIdx = (startIdx + (+this.pagination.pageSize));
            console.log(startIdx, endIdx);
            this.itemsToDisplay = this.allItems.slice(startIdx, endIdx);
        }


    }

    sort(a, b, value) {
        let tempa = eval('a.' + value);
        let tempb = eval('b.' + value);

        try {
            if (this.reverse) {
                if (tempa == '' || tempa == null) {
                    return -1;
                }
                if (tempa.toLowerCase() === tempb.toLowerCase()) {
                    return 0;
                } else {
                    if (tempa.toLowerCase() < tempb.toLowerCase()) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

            } else {
                if (tempa == '' || tempa == null) {
                    return -1;
                }
                if (tempa.toLowerCase() === tempb.toLowerCase()) {
                    return 0;
                } else {
                    if (tempa.toLowerCase() > tempb.toLowerCase()) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

            }
        } catch (e) {
            if (this.reverse) {
                if (tempa == '' || tempa == null) {
                    return -1;
                }
                if (tempa === tempb) {
                    return 0;
                } else {
                    if (tempa < tempb) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

            } else {
                if (tempa == '' || tempa == null) {
                    return -1;
                }
                if (tempa === tempb) {
                    return 0;
                } else {
                    if (tempa > tempb) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

            }
        }


        return 1;
    }

    showOptions(kyc) {
        for (var i = 0; i < this.itemsToDisplay.length; i++) {
            this.itemsToDisplay[i].showOptions = false;
        }
        kyc.showOptions = true;
    }

    onClickedOutside($event: Event) {

        for (var i = 0; i < this.itemsToDisplay.length; i++) {
            this.itemsToDisplay[i].showOptions = false;
        }
    }

    triggerDownload() {
        this.downloadFile(this.allItems);
    }

    downloadFile(data: any) {
        const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
        const header = Object.keys(data[0]);
        const csv = data.map((row) =>
            header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(';')
        );
        csv.unshift(header.join(';'));
        const csvArray = csv.join('\r\n');

        const a = document.createElement('a');
        const blob = new Blob([csvArray], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = 'myFile.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    getCustomData() {
        this.loading.show();
        this.http.makeRequestPost(['get_custom_data', {
            from: this.inputData.from['year'] + '-' + this.inputData.from['month'] + '-' + this.inputData.from['day'],
            to: this.inputData.to['year'] + '-' + this.inputData.to['month'] + '-' + this.inputData.to['day']
        }])
        .subscribe(
            (resp) => {
                this.loading.hide();
                if (resp.data) {
                    this.totalsToShow = resp.data.custom.total;
                    this.successToShow = resp.data.custom.success;
                    this.failedToShow = resp.data.custom.failed;
                    this.pendingToShow = resp.data.custom.pending;
                    this.incompleteToShow = resp.data.custom.incomplete;
                    this.notStarted = resp.data.custom.not_started;
                    this.allItems = resp.data.custom.data;
                } else {
                    this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                }
            },
            (error) => {
                this.loading.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }

    setStatus(num: number) {
        if (num == 1) {
            this.updateSatus(1);
        } else {
            this.showEmailModal = true;
            this.statusToUpdate = num;
        }
        // this.loading.show();
        // this.http.makeRequestPost(['set_status_manually', {
        //     status: num,
        //     id: this.kycToDisplay.id
        // }])
        // .subscribe(
        //     (resp) => {
        //         this.loading.hide();
        //         if (resp.data) {
        //             for (var i = 0; i < this.allItems.length; i++) {
        //                 if (this.allItems[i].id == resp.data.kyc.id) {
        //                     this.allItems[i] = resp.data.kyc;
        //                 }
        //             }
        //             for (var i = 0; i < this.itemsToDisplay.length; i++) {
        //                 if (this.itemsToDisplay[i].id == resp.data.kyc.id) {
        //                     this.itemsToDisplay[i] = resp.data.kyc;
        //                 }
        //             }
        //             this.showKycModal = false;
        //             this.toast.pop('success', 'Success', 'The status has been changed');
        //         } else {
        //             this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
        //         }
        //     },
        //     (error) => {
        //         this.loading.hide();
        //         this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
        //     }
        // );
    }

    updateSatus(num) {
        this.loading.show();
        this.http.makeRequestPost(['set_status_manually', {
            status: num,
            id: this.kycToDisplay.id,
            email_option: this.inputData.email_option
        }])
        .subscribe(
            (resp) => {
                this.loading.hide();
                if (resp.data) {
                    this.showKycModal = false;
                    this.showEmailModal = false;
                    this.toast.pop('success', 'Success', 'The status has been changed');
                } else {
                    this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                }
            },
            (error) => {
                this.loading.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }

    toggleEmail(kyc: any) {
        this.showEmailModal = !this.showEmailModal;
        this.kycToDisplay = kyc;
    }

    sendMail() {
        this.updateSatus(this.statusToUpdate);
    }
}
