import {Component, OnInit} from '@angular/core';
import {HttpService} from '../services/HttpService';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToasterService} from 'angular2-toaster';

@Component({
    selector: 'app-rejections',
    templateUrl: './rejections.component.html',
    styleUrls: ['./rejections.component.scss'],
    providers: [HttpService]
})
export class RejectionsComponent implements OnInit {

    public allItems = [];

    public itemsToDisplay = [];

    public sortByVal = '';

    public reverse = true;

    public inputData = {
        from: '',
        to: ''
    };

    public pagination;

    constructor(
        public http: HttpService,
        public loading: NgxSpinnerService,
        public toast: ToasterService,
    ) {
    }

    ngOnInit() {
    }

    onChangePage(evt) {
        this.pagination = evt.pager;
        console.log(evt);
        this.itemsToDisplay = evt.pageOfItems;
    }

    sortBy(value) {
        this.sortByVal = value;
        // console.log(this.pagination);
        this.reverse = !this.reverse;

        this.allItems = this.allItems.sort((a, b) => {
            return this.sort(a, b, value);
        });


        if (this.pagination.currentPage == 1) {
            this.itemsToDisplay = this.allItems.slice(0, this.pagination.pageSize);
        } else {
            const startIdx = ((this.pagination.currentPage - 1) * this.pagination.pageSize);
            const endIdx = (startIdx + (+this.pagination.pageSize));
            console.log(startIdx, endIdx);
            this.itemsToDisplay = this.allItems.slice(startIdx, endIdx);
        }


    }

    sort(a, b, value) {
        let tempa = eval('a.' + value);
        let tempb = eval('b.' + value);

        try {
            if (this.reverse) {
                if (tempa == '' || tempa == null) {
                    return -1;
                }
                if (tempa.toLowerCase() === tempb.toLowerCase()) {
                    return 0;
                } else {
                    if (tempa.toLowerCase() < tempb.toLowerCase()) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

            } else {
                if (tempa == '' || tempa == null) {
                    return -1;
                }
                if (tempa.toLowerCase() === tempb.toLowerCase()) {
                    return 0;
                } else {
                    if (tempa.toLowerCase() > tempb.toLowerCase()) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

            }
        } catch (e) {
            if (this.reverse) {
                if (tempa == '' || tempa == null) {
                    return -1;
                }
                if (tempa === tempb) {
                    return 0;
                } else {
                    if (tempa < tempb) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

            } else {
                if (tempa == '' || tempa == null) {
                    return -1;
                }
                if (tempa === tempb) {
                    return 0;
                } else {
                    if (tempa > tempb) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

            }
        }


        return 1;
    }

    getCustomData() {
        this.loading.show();
        this.http.makeRequestPost(['get_rejections', {
            from: this.inputData.from['year'] + '-' + this.inputData.from['month'] + '-' + this.inputData.from['day'],
            to: this.inputData.to['year'] + '-' + this.inputData.to['month'] + '-' + this.inputData.to['day']
        }])
        .subscribe(
            (resp) => {
                this.loading.hide();
                if (resp.data) {
                    this.allItems = resp.data;
                } else {
                    this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                }
            },
            (error) => {
                this.loading.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }

    triggerDownload() {
        this.downloadFile(this.allItems);
    }

    downloadFile(data: any) {
        const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
        const header = Object.keys(data[0]);
        const csv = data.map((row) =>
            header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(';')
        );
        csv.unshift(header.join(';'));
        const csvArray = csv.join('\r\n');

        const a = document.createElement('a');
        const blob = new Blob([csvArray], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = 'myFile.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}
