import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpService} from '../services/HttpService';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToasterService} from 'angular2-toaster';
import {JwPaginationComponent} from 'jw-angular-pagination';

@Component({
    selector: 'app-quotes',
    templateUrl: './quotes.component.html',
    styleUrls: ['./quotes.component.scss'],
    providers: [
        HttpService
    ]
})
export class QuotesComponent implements OnInit {

    public allQuotes = [];
    public itemsToDisplay;

    public numbersOnPage = 1;

    public reverse = true;
    public sortByVal = '';

    public pagination;


    constructor(
        public http: HttpService,
        public spinner: NgxSpinnerService,
        public toast: ToasterService
    ) {
    }

    ngOnInit() {
        this.spinner.show();
        this.http.makeRequestPost(['get_all_admin_quotes', {}])
        .subscribe(
            (resp) => {
                this.spinner.hide();
                if (resp.data) {
                    for (var i = 0; i < resp.data.length; i++) {
                        if (resp.data[i].quote && resp.data[i].quote.email != null) {
                            this.allQuotes.push(resp.data[i]);
                        }
                    }
                    // console.log(resp.data);
                } else {
                    this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                }
            },
            (error) => {
                this.spinner.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }

    oninit(event) {
        this.pagination = event;
    }


    onChangePage($event: any) {
        // console.log($event);
        this.itemsToDisplay = $event.pageOfItems;
        this.pagination = $event.pager;
    }

    openPdf(link) {
        if (link != '') {
            window.open('https://calculatorapi.solarcheck.co.za/' + link, '_blank');
        }

    }

    sortBy(value) {
        this.sortByVal = value;
        // console.log(this.pagination);
        this.reverse = !this.reverse;
        this.allQuotes = this.allQuotes.sort((a, b) => {
            let tempa = eval('a.' + value);
            let tempb = eval('b.' + value);

            if (this.reverse) {
                if (tempa === tempb) {
                    return -1;
                } else {
                    if (tempa < tempb) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

            } else {
                if (tempa === tempb) {
                    return -1;
                } else {
                    if (tempa > tempb) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

            }

            return 1;
        });

        if (this.pagination.currentPage == 1) {
            this.itemsToDisplay = this.allQuotes.slice(0, this.pagination.pageSize);
        } else {
            const startIdx = ((this.pagination.currentPage - 1) * this.pagination.pageSize);
            const endIdx = (startIdx + (+this.pagination.pageSize));
            console.log(startIdx, endIdx);
            this.itemsToDisplay = this.allQuotes.slice(startIdx, endIdx);
        }

        // this.itemsToDisplay = this.allQuotes;
        console.log(this.allQuotes);
        // console.log(this.pagination);
    }
}
