import {Component, OnInit} from '@angular/core';
import {HttpService} from '../services/HttpService';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToasterService} from 'angular2-toaster';
import {Router} from '@angular/router';

@Component({
    selector: 'app-tools',
    templateUrl: './tools.component.html',
    styleUrls: ['./tools.component.scss'],
    providers: [HttpService]
})
export class ToolsComponent implements OnInit {

    public inputData = {
        otp: '',
        otp2: '',
        cron: true
    };
    public showInfoModal = false;

    public kycToDisplay;

    constructor(
        public http: HttpService,
        public spinner: NgxSpinnerService,
        public toast: ToasterService,
        public router: Router
    ) {
    }

    ngOnInit() {
        this.spinner.show();
        this.http.makeRequestPost(['get_cron_status', {}]).subscribe(
            (resp) => {
                this.spinner.hide();
                if (resp.data) {
                    if (resp.data.active == 1) {
                        this.inputData.cron = true;
                    } else {
                        this.inputData.cron = false;
                    }
                } else {

                }
            },
            (error) => {
                this.spinner.hide();

            }
        );
    }

    updateToPending() {
        this.spinner.show();
        this.http.makeRequestPost(['update_to_pending', {
            otp: this.inputData.otp
        }]).subscribe(
            (resp) => {
                this.spinner.hide();
                if (resp.data) {
                    this.toast.pop('success', 'Success', 'The PV with the provided otp is now in a pending state.');
                } else {
                    this.toast.pop('error', 'Error', 'Could not find the otp');
                }
            },
            (error) => {
                this.spinner.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }

    getUidHistory() {

        this.spinner.show();
        this.http.makeRequestPost(['get_uid_history', {
            otp: this.inputData.otp2
        }]).subscribe(
            (resp) => {
                this.spinner.hide();
                if (resp.data) {
                    this.inputData.otp2 = '';
                    this.showInfoModal = true;
                    this.kycToDisplay = resp.data;
                    this.showInfoModal = true;
                } else {
                    this.toast.pop('error', 'Error', 'Could not find the otp');
                }
            },
            (error) => {
                this.spinner.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }

    toggleCronJob() {
        this.spinner.show();
        this.http.makeRequestPost(['toggle_cron', {
            cron: this.inputData.cron
        }]).subscribe(
            (resp) => {
                this.spinner.hide();
                if (resp.data) {
                    this.toast.pop('success', 'Success', 'The cron has been updated');
                } else {
                    this.toast.pop('error', 'Error', 'Could not find the otp');
                }
            },
            (error) => {
                this.spinner.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }
}
