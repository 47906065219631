import {Component} from '@angular/core';
import {ToasterConfig} from 'angular2-toaster';
import {Router} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'LoanbotAdmin';
    public config: ToasterConfig =
        new ToasterConfig({timeout: 5000, positionClass: 'toast-top-right'});

    constructor(public router: Router) {
    }


}
