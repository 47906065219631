import {Component, OnInit} from '@angular/core';
import {HttpService} from '../services/HttpService';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToasterService} from 'angular2-toaster';

@Component({
    selector: 'app-compare',
    templateUrl: './compare.component.html',
    styleUrls: ['./compare.component.scss'],
    providers: [HttpService]
})
export class CompareComponent implements OnInit {

    public inputData = {
        from: '',
        to: ''
    };

    public showInfoModalBool = false;

    public stats;

    constructor(
        public http: HttpService,
        public loading: NgxSpinnerService,
        public toast: ToasterService,
    ) {
    }

    ngOnInit() {
    }

    getCompareData() {
        this.loading.show();
        this.http.makeRequestPost(['get_compare_data', {
            from: this.inputData.from['year'] + '-' + this.inputData.from['month'] + '-' + this.inputData.from['day'],
            to: this.inputData.to['year'] + '-' + this.inputData.to['month'] + '-' + this.inputData.to['day']
        }])
        .subscribe(
            (resp) => {
                this.loading.hide();
                if (resp.data) {
                    this.stats = resp.data;
                    this.stats.from = this.inputData.from['year'] + '-' + this.inputData.from['month'] + '-' + this.inputData.from['day'];
                    this.stats.to = this.inputData.to['year'] + '-' + this.inputData.to['month'] + '-' + this.inputData.to['day'];

                    this.showInfoModalBool = true;

                } else {
                    this.toast.pop('error', 'Error', 'Please make sure you have a working data connection.');
                }
            },
            (error) => {
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection.');
                this.loading.hide();
            }
        );

    }
}
