import {Component, OnInit} from '@angular/core';
import {HttpService} from '../services/HttpService';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToasterService} from 'angular2-toaster';
import {isObject} from 'util';
import {Router} from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [HttpService]
})
export class LoginComponent implements OnInit {

    public inputData = {
        email: '',
        password: ''
    };


    constructor(
        public http: HttpService,
        public spinner: NgxSpinnerService,
        public toast: ToasterService,
        public router: Router
    ) {
    }

    ngOnInit() {
    }

    doLogin() {
        // if (this.inputData.email == 'admin@jaa.co.za' && this.inputData.password == 'p@55w0rd') {
        //     this.router.navigateByUrl('authenticated/kycs');
        // }
        this.spinner.show();
        this.http.makeRequestPost(['admin_login', this.inputData])
        .subscribe(
            (resp) => {
                this.spinner.hide();
                if (resp.data) {
                    if (isObject(resp.data)) {
                        console.log(resp.data);
                        this.router.navigateByUrl('authenticated/kycs');
                        localStorage.setItem('User_Details', JSON.stringify(resp.data));
                    } else {
                        this.toast.pop('error', 'Error', 'Could not log you in');

                    }
                } else {
                    this.toast.pop('error', 'Error', 'Could not log you in');
                }
            },
            (error) => {
                this.spinner.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }

}
