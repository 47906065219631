import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app-authenticated',
    templateUrl: './authenticated.component.html',
    styleUrls: ['./authenticated.component.scss']
})
export class AuthenticatedComponent implements OnInit {

    public currentUrl = '';

    public showLogoutModal = false;

    public userDetails = JSON.parse(localStorage.getItem('User_Details'));

    constructor(
        public router: Router
    ) {
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.currentUrl = val.url;
                console.log(this.currentUrl);
            }


        });

        this.userDetails = JSON.parse(localStorage.getItem('User_Details'));

    }

    ngOnInit() {
    }

    openUsers() {
        this.router.navigateByUrl('authenticated/users');
    }

    openDash() {
        this.router.navigateByUrl('authenticated/kycs');
    }

    openSettings() {
        this.router.navigateByUrl('');
    }

    openStats() {
        this.router.navigateByUrl('authenticated/stats');
    }

    changeToReport() {
        this.router.navigateByUrl('authenticated/reporting');
    }

    changeToLogging() {
        this.router.navigateByUrl('authenticated/logging');
    }

    toggleShowLogout() {
        console.log('show the log out modal');
        this.showLogoutModal = true;
    }

    changeToTools() {
        this.router.navigateByUrl('authenticated/tools');
    }

    openRejections() {
        this.router.navigateByUrl('authenticated/rejections');
    }

    changeToCompare() {
        this.router.navigateByUrl('authenticated/compare');
    }
}
