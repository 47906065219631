import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {AuthenticatedComponent} from './authenticated/authenticated.component';
import {QuotesComponent} from './quotes/quotes.component';
import {KycComponent} from './kyc/kyc.component';
import {ReportingComponent} from './reporting/reporting.component';
import {LoggingComponent} from './logging/logging.component';
import {ToolsComponent} from './tools/tools.component';
import {RejectionsComponent} from './rejections/rejections.component';
import {CompareComponent} from './compare/compare.component';


const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
        pathMatch: 'full'
    },
    {
        path: 'authenticated',
        component: AuthenticatedComponent,
        children: [
            {
                path: 'kycs',
                component: KycComponent
            },
            {
                path: 'reporting',
                component: ReportingComponent
            },
            {
                path: 'logging',
                component: LoggingComponent
            },
            {
                path: 'tools',
                component: ToolsComponent
            },
            {
                path: 'rejections',
                component: RejectionsComponent
            },
            {
                path: 'compare',
                component: CompareComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
