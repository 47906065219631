import {Component, NgZone, OnInit} from '@angular/core';
import {HttpService} from '../services/HttpService';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToasterService} from 'angular2-toaster';

@Component({
    selector: 'app-kyc',
    templateUrl: './kyc.component.html',
    styleUrls: ['./kyc.component.scss'],
    providers: [HttpService]
})
export class KycComponent implements OnInit {

    public stepToShow = 3;

    public sortByVal = '';
    public reverse = true;

    public success = [];
    public fail = [];
    public pending = [];

    public itemsToDisplay = [];

    public pagination;

    public User_Details;

    public showKycModal = false;
    public showInfoModal = false;

    public kycToDisplay;

    public showEmailModal = false;

    public inputData = {
        email_option: ''
    };

    public statusToUpdate = 0;


    constructor(
        public http: HttpService,
        public loading: NgxSpinnerService,
        public toast: ToasterService,
        public zone: NgZone
    ) {
        this.User_Details = JSON.parse(localStorage.getItem('User_Details'));

    }

    ngOnInit() {
        this.User_Details = JSON.parse(localStorage.getItem('User_Details'));
        this.loading.show();
        const path = this.User_Details.id == 1 ? 'get_all_kycs_tab' : 'get_client_kyc_tab';
        this.http.makeRequestPost([path,
            {
                client_id: this.User_Details.client.id,
                tab: this.stepToShow
            }
        ])
        .subscribe(
            (resp) => {
                this.loading.hide();
                if (resp.data) {
                    console.log(resp);
                    this.success = resp.data.success;
                    this.fail = resp.data.fail;
                    this.pending = resp.data.pending;
                } else {
                    this.toast.pop('error', 'Error', 'Could not get all the kyc\'s');
                }
            },
            (error) => {
                this.loading.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }

    setStepToShow(number) {
        this.stepToShow = number;
        this.sortByVal = '';
        this.reverse = true;

        this.User_Details = JSON.parse(localStorage.getItem('User_Details'));
        this.loading.show();
        const path = this.User_Details.id == 1 ? 'get_all_kycs_tab' : 'get_client_kyc_tab';
        this.http.makeRequestPost([path,
            {
                client_id: this.User_Details.client.id,
                tab: this.stepToShow
            }
        ])
        .subscribe(
            (resp) => {
                this.loading.hide();
                if (resp.data) {
                    console.log(resp);
                    this.success = resp.data.success;
                    this.fail = resp.data.fail;
                    this.pending = resp.data.pending;
                } else {
                    this.toast.pop('error', 'Error', 'Could not get all the kyc\'s');
                }
            },
            (error) => {
                this.loading.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }

    sortBy(value) {
        this.sortByVal = value;
        // console.log(this.pagination);
        this.reverse = !this.reverse;
        if (this.stepToShow == 1) {
            this.success = this.success.sort((a, b) => {
                return this.sort(a, b, value);
            });
        }

        if (this.stepToShow == 2) {
            this.fail = this.fail.sort((a, b) => {
                return this.sort(a, b, value);
            });
        }

        if (this.stepToShow == 3) {
            this.pending = this.pending.sort((a, b) => {
                return this.sort(a, b, value);
            });
        }


        if (this.stepToShow == 1) {
            if (this.pagination.currentPage == 1) {
                this.itemsToDisplay = this.success.slice(0, this.pagination.pageSize);
            } else {
                const startIdx = ((this.pagination.currentPage - 1) * this.pagination.pageSize);
                const endIdx = (startIdx + (+this.pagination.pageSize));
                console.log(startIdx, endIdx);
                this.itemsToDisplay = this.success.slice(startIdx, endIdx);
            }
        }
        if (this.stepToShow == 2) {
            if (this.pagination.currentPage == 1) {
                this.itemsToDisplay = this.fail.slice(0, this.pagination.pageSize);
            } else {
                const startIdx = ((this.pagination.currentPage - 1) * this.pagination.pageSize);
                const endIdx = (startIdx + (+this.pagination.pageSize));
                console.log(startIdx, endIdx);
                this.itemsToDisplay = this.fail.slice(startIdx, endIdx);
            }
        }

        if (this.stepToShow == 3) {
            if (this.pagination.currentPage == 1) {
                this.itemsToDisplay = this.pending.slice(0, this.pagination.pageSize);
            } else {
                const startIdx = ((this.pagination.currentPage - 1) * this.pagination.pageSize);
                const endIdx = (startIdx + (+this.pagination.pageSize));
                console.log(startIdx, endIdx);
                this.itemsToDisplay = this.pending.slice(startIdx, endIdx);
            }
        }


    }

    sort(a, b, value) {
        let tempa = eval('a.' + value);
        let tempb = eval('b.' + value);

        try {
            if (this.reverse) {
                if (tempa == '' || tempa == null) {
                    return -1;
                }
                if (tempa.toLowerCase() === tempb.toLowerCase()) {
                    return 0;
                } else {
                    if (tempa.toLowerCase() < tempb.toLowerCase()) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

            } else {
                if (tempa == '' || tempa == null) {
                    return -1;
                }
                if (tempa.toLowerCase() === tempb.toLowerCase()) {
                    return 0;
                } else {
                    if (tempa.toLowerCase() > tempb.toLowerCase()) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

            }
        } catch (e) {
            if (this.reverse) {
                if (tempa == '' || tempa == null) {
                    return -1;
                }
                if (tempa === tempb) {
                    return 0;
                } else {
                    if (tempa < tempb) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

            } else {
                if (tempa == '' || tempa == null) {
                    return -1;
                }
                if (tempa === tempb) {
                    return 0;
                } else {
                    if (tempa > tempb) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

            }
        }


        return 1;
    }

    onChangePage(evt) {
        this.pagination = evt.pager;
        console.log(evt);
        this.itemsToDisplay = evt.pageOfItems;
    }

    showOptions(kyc) {
        for (var i = 0; i < this.itemsToDisplay.length; i++) {
            this.itemsToDisplay[i].showOptions = false;
        }
        kyc.showOptions = true;
    }

    onClickedOutside($event: Event) {

        for (var i = 0; i < this.itemsToDisplay.length; i++) {
            this.itemsToDisplay[i].showOptions = false;
        }
    }

    toggleKycModal(kyc) {
        console.log(kyc);
        setTimeout(() => {
            this.zone.run(() => {
                for (var i = 0; i < this.itemsToDisplay.length; i++) {
                    this.itemsToDisplay[i].showOptions = false;
                }
            });
        });

        this.kycToDisplay = kyc;
        this.showKycModal = true;
    }

    toggleInfo(kyc: any) {
        setTimeout(() => {
            this.zone.run(() => {
                for (var i = 0; i < this.itemsToDisplay.length; i++) {
                    this.itemsToDisplay[i].showOptions = false;
                }
            });
        });

        this.kycToDisplay = kyc;
        this.kycToDisplay.id_face_result = JSON.parse(this.kycToDisplay.id_face_result);
        this.kycToDisplay.selfie_face_result = JSON.parse(this.kycToDisplay.selfie_face_result);
        this.kycToDisplay.id_text_result = JSON.parse(this.kycToDisplay.id_text_result);
        this.kycToDisplay.compare_result = this.kycToDisplay.compare_result;
        this.showInfoModal = true;
    }

    setStatus(num: number) {
        if (num == 1) {
            this.updateSatus(1);
        } else {
            this.showEmailModal = true;
            this.statusToUpdate = num;
        }

    }

    updateSatus(num) {
        this.loading.show();
        this.http.makeRequestPost(['set_status_manually', {
            status: num,
            id: this.kycToDisplay.id,
            email_option: this.inputData.email_option
        }])
        .subscribe(
            (resp) => {
                this.loading.hide();
                if (resp.data) {
                    for (var i = 0; i < this.pending.length; i++) {
                        if (this.pending[i].id == this.kycToDisplay.id) {
                            this.pending.splice(i, 1);
                        }
                    }
                    if (num == 1) {
                        // this.setStepToShow(1);
                        this.success.push(resp.data.kyc);
                        this.reverse = false;
                        this.sortBy('id');
                    } else {
                        // this.setStepToShow(2);
                        this.fail.push(resp.data.kyc);
                        this.reverse = false;
                        this.sortBy('id');
                    }
                    this.showKycModal = false;
                    this.showEmailModal = false;
                    this.toast.pop('success', 'Success', 'The status has been changed');
                } else {
                    this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                }
            },
            (error) => {
                this.loading.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }

    sendMail() {
        this.updateSatus(this.statusToUpdate);
    }
}
