import {Component, OnInit} from '@angular/core';
import {HttpService} from '../services/HttpService';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToasterService} from 'angular2-toaster';

@Component({
    selector: 'app-logging',
    templateUrl: './logging.component.html',
    styleUrls: ['./logging.component.scss'],
    providers: [HttpService]
})
export class LoggingComponent implements OnInit {
    public inputData = {
        from: ''
    };

    public data = {
        info: [],
        error: []
    };

    public stepToShow = 0;

    constructor(
        public http: HttpService,
        public loading: NgxSpinnerService,
        public toast: ToasterService,
    ) {
    }

    ngOnInit() {
    }

    getCustomData() {
        this.loading.show();
        this.http.makeRequestPost(['get_logs_json', {
            from: this.inputData.from['year'] + '-' + this.inputData.from['month'] + '-' + this.inputData.from['day'],
        }])
        .subscribe(
            (resp) => {
                this.loading.hide();
                console.log(resp);
                this.data.info = resp.data.info;
                this.data.error = resp.data.error;
            },
            (error) => {
                this.loading.hide();
                console.log(error);
                this.toast.pop('error', 'Error', 'No log file for the selected data');
            }
        );
    }

    setStepToShow(num) {
        this.stepToShow = num;
    }
}
